<template>

  <div>
  
  <h1>Gleispläne</h1>

  <ul v-if="list && list.length">
    <li v-for="g of list">
      <span>{{g.nummer}} {{g.kuerzel}} {{g.titel}}</span> <a :href="g.url" target="_blank">Link</a>
    </li>
  </ul>

  <ul v-if="errors && errors.length">
    <li v-for="error of errors">
      {{error.message}}
    </li>
  </ul>
  
  </div>
</template>

<style scoped>
h1 {
  color: blue
}
</style>

<script>

import axios from 'axios';

export default {
  data() {
    return {
      list: [],
      errors: []
    }
  },

  created() {
    axios.get('/gp.json')
    .then(response => {
      // JSON responses are automatically parsed.
      this.list = response.data
	  
	  var contentToCache = [];
	  	  
	  for (var index = 0; index < this.list.length; index++) {
		// console.log(this.list[index]);
		//fetch();
		
		contentToCache.push(this.list[index].url);
	  }
	  
		console.log(contentToCache);
	  
		caches.open('doc-cache-v1').then((cache) => {
		  cache.addAll(contentToCache);
		})
	  
	  localStorage.list = JSON.stringify(this.list);
    })
    .catch(e => {
      //this.errors.push(e);
	  console.log(e);
	  this.list = JSON.parse(localStorage.list);
    })

  }
}

</script>